import { createReducer, on } from "@ngrx/store";
import { SbGetSoccerMatch, SbSetSoccerMatch, SoccerMatchFailure } from "../actions";
import { soccerMatchAdapter, soccerMatchInitialState } from "../adapters";

export const SoccerMatchReducer = createReducer(
  soccerMatchInitialState,
  on(SbGetSoccerMatch, state => {
    return { ...state };
  }),
  on(SbSetSoccerMatch, (state, { payload }) => {
    return soccerMatchAdapter.setOne(payload.items, {
      ...state
    });
  }),
  on(SoccerMatchFailure, (state, payload) => {
    return {
      ...state,
      operationResult: {
        id: payload.identifier,
        isSuccessful: false,
        operationType: payload.operationType
      }
    };
  })
);
