import { createReducer, on } from '@ngrx/store';
import * as Actions from '../actions/bet-type-marings.actions';
import { betTypeMarginsAdapter, betTypeMarginsInitialState } from '../adapters';
import { OperationType } from 'src/app/sport-betting/common/models';

export const BetTypeMarginsReducer = createReducer(
  betTypeMarginsInitialState,
  on(Actions.SbSetBetTypeMargins, (state, { payload }) => {
    return betTypeMarginsAdapter.setAll(payload.data, {
      ...state,
      totalCount: payload.totalCount
    });
  }),
  on(Actions.SbUpdateBetTypeMargin, state => ({
    ...state,
    operationResult: null
  })),
  on(Actions.SbUpdateBetTypeMarginSuccess, (state, { payload }) => {
    return betTypeMarginsAdapter.updateOne({
      id: payload.marginId,
      changes: {
        ...payload,
        id: payload.marginId
      }
    }, {
      ...state,
      operationResult: {
        id: payload.marginId,
        isSuccessful: true,
        operationType: OperationType.Update
      }
    });
  }),
  on(Actions.BetTypeMarginsFailure, (state, payload) => {
    return {
      ...state,
      operationResult: {
        id: payload.identifier,
        isSuccessful: false,
        operationType: payload.operationType
      }
    };
  })
);