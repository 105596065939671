import { createAction, props } from "@ngrx/store";
import { FailAction, Filtering } from "src/app/sport-betting/common/models";
import { UpdateBetTypeMargin } from "../../models/update-bet-type-margin.model";
import { BetTypeMargin } from "../../models/bet-type-margins.model";

const SB_GET_BET_TYPE_MARGINS = '[SBBTM] Get bet type margins';
const SB_SET_BET_TYPE_MARGINS = '[SBBTM] Set bet type margins';
const SB_UPDATE_BET_TYPE_MARGIN = '[SBBTM] Update bet type margins';
const SB_UPDATE_BET_TYPE_MARGIN_SUCCESS = '[SBBTM] Update bet type margins SUCCESS';

const FAILURE = '[SBBTM] Failure';

export const SbGetBetTypeMargins = createAction(SB_GET_BET_TYPE_MARGINS, props<{ payload: Filtering }>());
export const SbSetBetTypeMargins = createAction(SB_SET_BET_TYPE_MARGINS, props<{
  payload: {
    data: Array<BetTypeMargin>,
    totalCount: number;
  }
}>());
export const SbUpdateBetTypeMargin = createAction(SB_UPDATE_BET_TYPE_MARGIN, props<{ payload: UpdateBetTypeMargin }>());
export const SbUpdateBetTypeMarginSuccess = createAction(SB_UPDATE_BET_TYPE_MARGIN_SUCCESS, props<{ payload: UpdateBetTypeMargin }>());

export const BetTypeMarginsFailure = createAction(FAILURE, props<FailAction>());