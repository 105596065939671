import brand from '../../assets/brand.json';

export class AppSettings {
  public static PROTOCOL = 'https://';
  public static BASE_URL_ENDPOINT = 'aq.idobet.com';
  public static LOCAL_BASE_URL = 'aq.idobet.com';

  public static BASE_API_ENDPOINT = 'services/';
  public static MICRO_SERVICES_BASE_API_ENDPOINT = 'mservices/';
  public static API_ENDPOINT = 'backofficeapi/';
  public static API_ENDPOINT_SERVICES = 'services/backofficeapi/';
  public static EV_API_ENDPOINT = 'services/evapi/api/';
  public static GAMES_API = 'gameapi/api/';
  public static DYNAMIC_API = 'dynamic/Execute';
  public static BACKOFFICE_API_V2 = 'boapi/api/';
  public static BACKOFFICE_API = 'boapi/api/';
  public static EVENT_API = 'evapi/api/';
  public static LOTTO_API_ENDPOINT = 'services/lottoapi/api/';
  public static PRINT_SERVICE_API_GET_ORDER = 'Services/PrintService/Orders/GetOrder?q=';
  public static API_NAME = 'backofficeapi';
  public static DEFAULT_LANGUAGE = 'en';
  public static VERSION = 'V' + brand.version;
  public static CHANNELID = 5;
  public static DEFAULT_LANGUAGE_ISO = 'en-US';
  public static ACTIVE_FILTER_TEXT_MAX_LENGTH = 40;
  public static BRAND_DOMAIN = window.location.host.substring(window.location.host.indexOf('.') + 1);
  public static BRAND_NAME = ''; // NOT TOUCH!!  brand name is set in app config service.
  public static MOCK_BASE_API = 'https://41139104-7804-47f1-9ba6-8bed600d5d78.mock.pstmn.io/';
}

export class APIMethods {
  public static LOGIN_METHOD = 'authentication/LoginUser';
  public static LOGOUT_METHOD = 'sport/LogoutUser';
  public static GET_USERS = 'user/GetUsers';
  public static SAVE_USER = 'user/SaveUser';
  public static GET_POOL_TEMPLATES = 'sport/GetPoolTemplateList';
  public static GET_SPORTS_TREE = 'Event/BOGetSportTree';
  public static GET_SPORT_GAMES = 'Event/GetSportGames';
  public static GET_EVENTS = 'event/getevents';
  public static GET_CASINO_GAMES = 'Sport/GetCasinoGames';
  public static SAVE_CASINO_CATEGORY = 'cms/SaveCasinoCategory';

  public static BO_SEARCH_EVENTS = 'Event/BOSearchEvents';
  public static GET_TEAMS_BY_LEAGUE_ID = 'Event/GetTeamsByLeagueId';
  public static CUSTOMIZE_EVENT = 'Event/UpdateSportGameCustomization';
  public static GET_POOL_BY_ID = 'Bet/GetPoolById';
  public static GET_ORDER_DATA = 'Bet/GetBetSlipData';
  public static GET_POOL_TEMPLATE_PRIZE = 'sport/GetPoolTemplatePrize';
  public static GET_POOL_GAMES = 'sport/GetPoolGames';
  public static LOCK_ORDER = 'sport/LockBetSlip';
  public static UNLOCK_ORDER = 'sport/UnLockBetSlip';
  public static REVERT_ORDER = 'sport/RevertBetSlip';
  public static REFUND_ORDER = 'sport/RefundBetSlip';
  public static CANCEL_ORDER = 'sport/CancelBetSlip';
  public static AUTOCOMPLETE_USER = 'sport/AutocompleteUser';
  public static LOCK_AND_UNLOCK_USER = 'sport/LockAndUnLockUser';

  public static ADD_CUSTOMER_NOTE = 'sport/AddCustomerNote';

  public static UPDATE_TRANSFER_COMMENT = 'sport/UpdateTransferComment';
  public static UPDATE_TRANSACTION_COMMENT = 'sport/UpdateTransactionComment';

  public static UNDO_CANCEL_ORDER = 'sport/UndoCancelBetSlip';
  public static EXTEND_EXPIRATION_DATE_ORDER = 'sport/ExtendBetSlipExpirationDate';
  public static REVERSE_LOCK_ORDER = 'sport/ReversePaymentAndLockBetSlip';
  public static UPDATE_BETSLIP_ROW = 'sport/UpdateBetSlipRow';
  public static SAVE_POOL_TEMPLATE_PRIZE = 'sport/SavePoolTemplatePrize';
  public static SAVE_POOL = 'sport/SavePoolGame';
  public static CALCULATE_POOL = 'Sport/CalculatePool';
  public static EXPORT_POOL_LINES = 'metadata/ExportPoolGameLines';
  public static SAVE_POOL_PRIZE = 'sport/UpdatePoolPrize';
  public static SAVE_POOL_MATCH = 'sport/SavePoolEvent';
  public static GET_TERMINALS = 'metadata/GetTerminals';
  public static SAVE_BRANCH = 'metadata/SaveBranch';
  public static SAVE_TERMINAL = 'metadata/SaveTerminal';
  public static GET_TEMPLATES = 'sport/GetFormTemplates';
  public static GET_TURBO_CASHOUT_RULES = 'sport/GetTurboCashOutRules';
  public static GET_TURBO_CASHOUT_PROFITABILITY_REPORT = 'sport/GetTurboCashOutProfitabilityReport';
  public static GET_TURBO_CASHOUT_PROFITABILITY_REPORT_SUMMARY = 'sport/GetTurboCashOutProfitabilitySummaryReport';
  public static GET_BET_TYPES = 'sport/GetBetTypes';
  public static GET_CUSTOMERS_TRANSFERS = 'sport/GetCustomersTransfers';
  public static GET_CUSTOMER_STATEMENTS = 'sport/GetCustomerStatements';
  public static GET_USER_BONUSES = 'sport/GetUserBonuses';
  public static GET_PAYMENT_METHODS = 'sport/GetPaymentMethods';
  public static GET_MARKETING_CAMPAIGNS = 'sport/GetMarketingCampaigns';
  public static CHANGE_TURBO_CAHOUT_STATUS = 'sport/ChangeTurboCashOutStatus';
  public static SAVE_TURBO_CASHOUT_RULE = 'sport/SaveTurboCashOutRule';
  public static SAVE_TURBO_CAHOUT_GENERAL_SETTINGS = 'sport/SaveTurboCashOutGeneralSettings';
  public static GET_BRANCHES = 'sport/GetBranches';
  public static GET_BETSLIPS = 'orders/GetBetSlips';
  public static GET_BRANCH_SUMMARY = 'sport/GetBranchSummary';
  public static GET_DATA_TEMPLATES = 'sport/GetFormTemplateData';
  public static SET_DATA_TEMPLATES = 'sport/SetFormTemplateData';
  public static GET_ENUMS = 'sport/GetEnums';
  public static UPDATE_ORDER_BETS_MANUALLY = 'sport/UpdateOrderBetsManually';
  public static SAVE_POOL_TEMPLATE = 'sport/SavePoolTemplate';
  public static GET_CUSTOMERS = 'sport/GetCustomers';
  public static GET_BATCH_QUERY_D = 'voucher';
  public static ONLINE_VOUCHER_LIST = 'onlineVoucherListReducer';
  public static GET_CUSTOMER_PROFILE = 'sport/GetCustomerProfile';
  public static GET_CUSTOMER_NOTES = 'sport/GetCustomerNotes';
  public static GET_CUSTOMER_LINK_ACCOUNTS = 'sport/GetCustomerLinkAccounts';
  public static GET_AVAILABLE_ONLINE_CAMPAIGN_FOR_USER = 'sport/GetAvailableOnlineCampaignsForUser';
  public static REMOVE_LINKED_ACCOUNT = 'sport/RemoveLinkedAccount';
  public static ADD_LINKED_ACCOUNT = 'sport/AddLinkedAccount';
  public static MANUAL_CONFIRM_USER = 'sport/ManualConfirmUser';
  public static UPDATE_CUSTOMER_PROFILE = 'sport/UpdateCustomerProfile';
  public static LEAGUE_AUTO_COMPLETE = 'metadata/GetAutoCompleteLeagues';
  public static GET_TERMINAL_LOCATION = 'user/GetTerminalLocation';
  public static GET_FILTERS_FOR_BRAND_SETTINGS = 'sport/GetFiltersForBrandSettings';
  public static CHANGE_CUSTOMER_PASSWORD = 'sport/ChangeCustomerPassword';
  public static DIRECT_DEPOSIT = 'sport/DirectDeposit';
  public static GET_DEPOSIT_LIMIT = 'DepositLimits/GetDepositLimit';
  public static ATTACH_BONUS = 'sport/AttachOnlineCampaignToUser';
  public static DIRECT_WITHDRAW = 'sport/DirectWithdraw';
  public static GET_CUSTOMER_BALANCE_DATA = 'sport/GetCustomerBalanceData';
  public static GET_CUSTOMER_MONTHLY_DEPOSIT_WITHDRAW_BALANCE_DATA = 'sport/GetDataForDepositWithdrawChart';
  public static GET_CUSTOMER_MONTHLY_REVENUE_BALANCE_DATA = 'sport/GetDataForRevenueSummaryChart';
  public static GET_CUSTOMER_BALANCE_DATA_FOR_SPECIFIC_RANGE = 'sport/GetCustomerBalanceDataForSpecificDateRange';
  public static COMMIT_DEPOSIT = 'Payment/CommitDeposit';
  public static PAYMENT_BACKOFFICE = 'payment/backoffice/api/';
  public static APPROVE_TRANSFER = '/mservices/payment/backoffice/api/transfer/ApproveTransfer';
  public static CANCEL_TRANSFER = '/mservices/payment/backoffice/api/transfer/CancelTransfer';
  public static UNDO_CANCEL_TRANSFER = '/mservices/payment/backoffice/api/transfer/UndoCancelTransfer';
  public static COMMIT_WITHDRAW = 'Payment/CommitWithdraw';
  public static CHANGE_TRANSFER_TO_PENDING = 'sport/ChangeTransferToPending';
  public static RESEND_OTP = 'sport/ReSendUserVerificationOTP';
  public static GET_KEEP_ALIVE = 'sport/GetKeepAlive';
  public static GET_SUPPORTED_LANGUAGES = 'sport/GetSupportedLanguages';
  public static GET_USER_BY_TOKEN = 'sport/GetUserByToken';
  public static GET_EXPENSES_CATEGORIES = 'sport/GetExpensesCategories';
  public static GET_EXPENSES_ITEMS = 'sport/GetExpensesItems';
  public static GET_EXPENSES = 'sport/GetExpenses';
  public static GET_EXPENSES_MONTHS = 'sport/GetExpensesMonths';
  public static GET_SETTINGS_JSON = 'sport/GetSettingsJson';
  public static SAVE_SETTINGS_JSON = 'sport/SaveSettingsJson';
  public static GET_ACTIVE_CAMPAIGNS = 'sport/GetActiveCampaigns';
  public static CONNECT_USERS_TO_CAMPAIGNS = 'sport/ConnectUsersToCampaign';
  public static GET_BONUSES_BY_TYPE = 'sport/GetBonusesByType';
  public static SET_SELF_USER_EXCLUSION = 'UserExclude/SetSelfUserExclusion';
  public static GET_USER_ACCESS = 'UserAccess/GetUserAccessList';
  public static USER_ACCESS_LOGOUT = 'UserAccess/LogoutUser';
}

export class APIMethodsFastGames {
  public static Save_FastGame = 'FastGames/SaveFastGame/';
  public static Get_Batch_Statistics = 'FastGames/GetBatchStatistics';
  public static Get_FastGames_Statistics = 'FastGames/GetFastGamesStatistics';
  public static Try_Again_Batch = 'FastGames/RetryCreateBatchTickets';
  public static Get_FastGames = 'FastGames/GetFastGames';
  public static Get_Batch_Templates = 'FastGames/GetBatchTemplates';
  public static Get_Batch_Template_Details = 'FastGames/GetBatchTemplateDetails';
  public static Save_Batch_Template = 'FastGames/SaveBatchTemplate';
  public static Edit_Batch_Template = 'FastGames/EditBatchTemplate';
  public static Mark_Batch_Template_For_Edit = 'FastGames/MarkBatchTemplateForEdit';
}

export class APIMethodsGames {
  public static DYNAMIC_QUERY = 'Dynamic/Execute/';
  public static BO_QUERY = 'bo/';
}

export class APIMethodsNotificationTemplates {
  public static GET_NOTIFICATION_TEMPLATES = 'notificationTemplate/GetNotificationTemplates';
  public static GET_NOTIFICATION_TEMPLATE = 'notificationTemplate/GetNotificationTemplate';
  public static SAVE_NOTIFICATION_TEMPLATES = 'notificationTemplate/Save';
}

export class APIMethodsCMS {
  public static DYNAMIC_QUERY = 'cms/Execute';
}

export class APIMethodOnline {
  public static GET_ONLINE_CUSTOMERS = 'BoGetCustomers';
  public static GET_ONLINE_PREPAID_CUSTOMERS = 'BoGetPrePaids';
  public static GET_ONLINE_CUSTOMERS_SUMMARY = 'BOGetOnlineSummary';
}

export class APIMethodsLotto {
  public static GET_DATA = 'Lotto/GetData';
  public static GET_USER_DATA = 'Lotto/GetUserData';
  public static GET_LOTTERY_TYPES = 'GetLotteryTypes';
  public static GET_LOTTERY_TYPE_BY_ID = 'GetLotteryTypeById';
  public static GET_BET_LOTTERY_TYPE_BY_ID = 'GetBetLotteryTypesById';
  public static GET_LOTTERY_PRIZES = 'GetLotteryPrizes';
  public static GET_LOTTO_LOGS = 'GetLogBOChanges';
  public static UPDATE_LOTTO_TYPE_BY_ID = 'UpdateLotteryTypeById';
  public static UPDATE_LOTTO_LINE_TYPE = 'UpdateBetLotteryLineTypes';
  public static CONFIGURE_BET_LOTTERY_TYPE_BY_ID = 'ConfigureBetLotteryTypesById';
  public static UPDATE_BET_LOTTO_TYPE_ORDER = 'UpdatebetLotteryTypesOrders';
  public static UPDATE_BET_LOTTO_TYPE_BY_ID = 'UpdatebetLotteryTypebyId';
  public static GET_LOTTO_TOTALS_REPORT = 'LottoGetTotals';
  public static GET_DRAW_SELECTED_NUMBERS = 'GetDrawSelectedNumbers';
  public static GET_LOTTERY_DRAWS = 'BOGetDrawsToUpdate';
  public static UPDATE_LOTTERY_DRAWS = 'UpdateDrawResults';
  public static UPDATE_LOTTERY_PRIZES = 'UpdateDrawPrizes';
  public static SETTLE_BETS = 'SettleBets';
  public static UPDATE_LOTTERY_JACKPOT = 'UpdateDrawJackpot';
  public static GET_CURRENCIES = 'GetCurrencies';
  public static GET_USER_TICKETS = 'GetUserTickets';
  public static CANCEL_TICKET = 'CancelTicket';
  public static METHOD_GROUP = 'lotto';
  public static DYNAMIC_QUERY = 'Dynamic/Execute';
  public static DYNAMIC_QUERY_LOTTO = 'Dynamic/ExecuteLotto';
  public static DYNAMIC_QUERY_DOWNLOAD_LOTTO_EXCEL = 'DynamicFile/ExecuteMemory';
}

export class RouteConstants {
  public static NEW_ITEM = 'new';
  public static CLONE_ITEM = 'clone';
  public static LOGIN_PAGE = '/login';
  public static USERS_PAGE = 'administration/users';
  public static ABOUT_PAGE = 'about';
  public static ACCESS = 'accessdenied';
  public static NOTFOUND = '404';
  public static ERROR = 'error';
  public static DASHBOARD = 'home';
  public static CUSTOMER_CARE_SEARCH = 'crm/customer-care';
  public static VOUCHER_BATCH_SEARCH = 'crm/voucher-batch/batch';
  public static VOUCHER_LIST_SEARCH = 'crm/voucher-batch/list';
  public static HOME = 'home';
}

export class AwsS3Constants {
  public static S3Bucket = 'idobetgaming';
  public static S3Credentials = {
    accessKeyId: 'AKIAU66LSZXW3HILDXSH',
    secretAccessKey: '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',
    region: 'eu-central-1',
  };
}

export class MenuConstants {
  public static SETTING = 'setting';
  public static EXPENSES = 'expenses';
  public static EVENTS = 'events';
  public static CMS = 'cms';
  public static HOME_PAGE = 'homePage';
  public static SPORTS_BOOK = 'sportsBook';
  public static BETTING = 'betting';
  public static GAMES = 'games';
  public static BRANCHES = 'branches';
  public static ADMINISTRATION = 'administration';
  public static FINANCE = 'finance';
  public static ONLINE = 'online';
  public static POS_AGENT = 'posAgent';
  public static D2D_AGENT = 'd2dAgent';
  public static MONITORING = 'monitoring';
  public static HELP = 'help';
}

export class ReducersConstants {
  public static ADMINISTRATION = 'administrationReducer';
  public static ROLES_AND_PERMISSIONS = 'rolesAndPermissionsReducer';
  public static EXPOSURE = 'exposureReducer';
  public static ONLINE = 'onlineReducer';
  public static ACTIVE_CAMPAIGNS = 'activeCampaignsReducer';
  public static BRAND_SETTINGS = 'brandSettingsReducer';
  public static GAMES = 'gamesReducer';
  public static AUTH = 'authReducer';
  public static METADATA = 'metadataReducer';
  public static LAYOUT = 'layoutReducer';
  public static ERROR = 'errorReducer';
  public static SETTING = 'settingReducer';
  public static BETTING = 'bettingReducer';
  public static SPORTS_BOOK = 'sportsBookReducer';
  public static BETSLIPS = 'betslipsReducer';
  public static ONLINE_CUSTOMERS_LIST = 'onlineCustomersListReducer';
  public static ONLINE_VOUCHER_LIST = 'onlineVoucherListReducer';
  public static ONLINE_VOUCHER_REPORT = 'reportDataReducer';
  public static VOUCHER_LIST = 'onlineListReducer';
  public static ORDER = 'orderReducer';
  public static LOTTO_SPORTS_BOOK = 'lottoSportsBookReducer';
  public static SETTINGS_SPORTS_BOOK = 'settingsSportsBookReducer';
  public static EVENTS_METADATA = 'eventsMetadataReducer';
  public static EVENTS = 'eventsReducer';
  public static BO_FILTER = 'boFilterReducer';
  public static USER_ACCESS = 'userAccessReducer';
  public static SPORT_BETTING = 'sportBetting';
  public static NOTIFICATION_TEMPLATES = 'notificationTemplateReducer';

  // Expenses
  public static EXPENSES = 'expensesReducer';
  // public static EXPENSES_MONTHS = 'expensesMonthsReducer'
  // public static EXPENSES_MANAGEMENT = 'expensesManagementReducer'
  public static EXPENSES_REQUESTS = 'expensesRequestsReducer';
  public static EXPENSES_REPORT = 'expensesReportReducer';
  public static BRANCH_SUMMARY = 'branchSummaryReducer';

  // Online - CMS reducers
  public static CMS = 'cmsReducer';
  public static CMS_LANGUAGES = 'cmsLanguagesReducer';
  public static CMS_BANNERS = 'cmsBannerReducer';
  public static CMS_HTMLS = 'cmsHtmlsReducer';
  public static CMS_SEO = 'cmsSEOReducer';
  public static CMS_MENU_MANAGEMENT = 'cmsMenuManagementReducer';

  // Voucher
  public static VOUCHER = 'voucherReducer';

  // Instant Win fast Games
  public static INSTANT_WIN = 'fastGamesManagementReducer';
}

export class AdministrationConstants {
  public static GENERATE = 'GENERATE';
}

export class GeneralConstants {
  public static PIPE_DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm:ss';
  public static PIPE_DATE_TIME_FORMAT_SHORT = 'DD/MM/YYYY HH:mm';
  public static PIPE_DATE_TIME_FORMAT_MOMENT_USE = 'DD/MM/YYYY HH:mm:ss';
  public static VERY_SHORT_DATE_FORMAT = 'DD/MM/YYYY';
  public static PIPE_DATE_FORMAT = 'dd/MM/yyyy';
  public static SHORT_DATE_FORMAT = 'DD/MM/YYYY HH:mm';
  public static BIRTH_DAY_DATE_FORMAT = 'DD/MM/YYYY';
  public static LONG_DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss';
  public static ORDER_BY_DESC = '-1';
  public static ORDER_BY_ASC = '1';
  public static OLD_BACKOFFICE_BETSLIPS = 'oldbo/Reports/ClientOrder';
  public static POOL_SETTLE = 4;
  public static DELETE_POOL_MATCH = 'Are you sure?';
  // public static INPUT_PATTERN = '[0-9]+([\.][,][0-9])*';
  public static INPUT_PATTERN = '^[0-9.,-/]+$';
  public static PAGING_DEFAULT = [10, 50, 100, 500];
  public static IS_DELETED = 'isdeleted';
  public static IS_CLONED = 'iscloned';
  public static IS_ARCHIVED = 'isarchived';
  public static ONE_DAY_DURATION = 60000 * 60 * 24;
  public static ONE_YEAR_DURATION = 60000 * 60 * 24 * 365;
  public static ONE_MINUTE_DURATION = 60000;
  public static ONE_SECOND_IN_MS = 1000;
  // public static PASSWORD_PATTERN = '^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{4,25}$';
  public static PASSWORD_PATTERN = '^.{4,25}$';
  // max export to excel (old 59999)
  public static MAX_EXPORT_RECORDS = 100000;
  public static MAX_EXPORT_BETSLIP_RECORDS = 10000;
  public static CELEMENT1 = '#cElement1';
  public static PAGINATION_OPTIONS = [10, 20, 50, 100, 500];
}

export class SportsbookConstants {
  public static MAX_LINE = 1024;
  public static CORRECT_SCORE_MAX_LINE = 1;
}

export class TranslationWords {
  public static DELETE_ROW = 'Delete_Row';
  public static DELETE_ROW_QUESTION = 'Delete_Row_Question';
  public static ARCHIVE_ROW = 'Archive_Row';
  public static ARCHIVE_ROW_QUESTION = 'Archive_Row_Question';
  public static UNARCHIVE_ROW = 'Unarchive_Row';
  public static UNARCHIVE_ROW_QUESTION = 'Unarchive_Row_Question';
}

export class PermissionActionsCodes {
  public static CUSTOMER_LOCK_ACTION = 'CUSTOMER_LOCK_ACTION';
  public static CUSTOMER_RESET_PASSWORD_ACTION = 'CUSTOMER_RESET_PASSWORD_ACTION';
  public static CUSTOMER_PROFILE_SOCIAL_MEDIA_ACTION = 'CUSTOMER_PROFILE_SOCIAL_MEDIA_ACTION';
  public static CUSTOMER_PROFILE_SECURITY_ACTION = 'CUSTOMER_PROFILE_SECURITY_ACTION';
  public static CUSTOMER_MANUAL_VALIDATION_ACTION = 'CUSTOMER_MANUAL_VALIDATION_ACTION';
  public static CUSTOMER_RESEND_OTP_ACTION = 'CUSTOMER_RESEND_OTP_ACTION';
  public static USER_LIST_RESET_PASSWORD_ACTION = 'CUSTOMER_RESET_PASSWORD_ACTION';
  public static SPORTSBOOK_CHANGE_CASHOUT_STATUS_ACTION = 'SPORTSBOOK_CHANGE_CASHOUT_STATUS_ACTION';
  public static SPORTSBOOK_CASHOUT_SETTINGS_ACTION = 'SPORTSBOOK_CASHOUT_SETTINGS_ACTION';

  //Vouchers
  public static VOUCHER_CANCEL_BATCH = 'VOUCHER_CANCEL_BATCH';
  public static VOUCHER_DOWNLOAD_BATCH_EXCEL = 'VOUCHER_DOWNLOAD_BATCH_EXCEL';
  public static VOUCHER_CANCEL_VOUCHER = 'VOUCHER_CANCEL_VOUCHER';

  //Deposit/Withdraw
  public static CUSTOMER_APPLY_DEPOSIT_ACTION = 'CUSTOMER_APPLY_DEPOSIT_ACTION';
  public static CUSTOMER_APPLY_WITHDRAWAL_ACTION = 'CUSTOMER_APPLY_WITHDRAWAL_ACTION';
  public static CUSTOMERS_DEPOSIT_WITHDRAW_COMMENTS_ACTION = 'CUSTOMERS_DEPOSIT_WITHDRAW_COMMENTS_ACTION';
  public static CUSTOMER_UNDO_DECLINE_WITHDRAW_ACTION = 'CUSTOMER_UNDO_DECLINE_WITHDRAW_ACTION';
  public static CUSTOMER_UNDO_DECLINE_DEPOSIT_ACTION = 'CUSTOMER_UNDO_DECLINE_DEPOSIT_ACTION';

  //Transfers
  public static TRANSFER_APPROVE_TRANSFER = 'TRANSFER_APPROVE_TRANSFER';
  public static TRANSFER_CANCEL_TRANSFER = 'TRANSFER_CANCEL_TRANSFER';
  public static TRANSFER_UNDO_CANCEL_TRANSFER = 'TRANSFER_UNDO_CANCEL_TRANSFER';

  //Statement
  public static CUSTOMER_STATEMENTS_EDIT_COMMENTS_ACTION = 'CUSTOMER_STATEMENTS_EDIT_COMMENTS_ACTION';

  //Balance
  public static CUSTOMER_ATTACH_BONUS_ACTION = 'CUSTOMER_ATTACH_BONUS_ACTION';

  // Expenses
  public static EXPENSES_SELF_APPROVE_ACTION = 'EXPENSES_SELF_APPROVE_ACTION';
  public static EXPENSES_APPROVE_EXPENSES_ACTION = 'EXPENSES_APPROVE_EXPENSES_ACTION';
  public static EXPENSES_CANCEL_EXPENSES_ACTION = 'EXPENSES_CANCEL_EXPENSES_ACTION';
  public static EXPENSES_CANCEL_APPROVE_EXPENSES_ACTION = 'EXPENSES_CANCEL_APPROVE_EXPENSES_ACTION';
  public static EXPENSES_APPROVE_EXPENSES_PAYMENTS_ACTION = 'EXPENSES_APPROVE_EXPENSES_PAYMENTS_ACTION';
  public static LOTTO_UPDATE_JACKPOT_PRIZE_ACTION = 'LOTTO_UPDATE_JACKPOT_PRIZE_ACTION';
  public static LOTTO_UPDATE_DRAW_RESULT_ACTION = 'LOTTO_UPDATE_DRAW_RESULT_ACTION';
  public static LOTTO_CALCULATE_WIN_ACTION = 'LOTTO_CALCULATE_WIN_ACTION';
  public static LOTTO_DRAW_SETTLEMENT_ACTION = 'LOTTO_DRAW_SETTLEMENT_ACTION';
  public static LOTTO_WIN_RESULTS_ACTION = 'LOTTO_WIN_RESULTS_ACTION';

  // Reports
  public static EXPORT_DATA = 'EXPORT_DATA';
}

export class PermissionsScreensCodes {
  public static INSTANT_WIN_BATCH_MANAGEMENT_SCREEN = 'INSTANT_WIN_BATCH_MANAGEMENT_SCREEN';
  public static INSTANT_WIN_GAME_MANAGEMENT_SCREEN = 'INSTANT_WIN_GAME_MANAGEMENT_SCREEN';
  public static PREVIEW_FAST_GAME_BATCH_TEMPLATE = 'PREVIEW_FAST_GAME_BATCH_TEMPLATE';
  public static EDIT_FAST_GAME_BATCH_TEMPLATE = 'EDIT_FAST_GAME_BATCH_TEMPLATE';
  public static BET_TYPE_MANAGEMENT = 'BET_TYPE_MANAGEMENT';
  public static EVENT_LOBBY_MANAGEMENT = 'EVENT_LOBBY_MANAGEMENT';
  public static EVENT_MANAGEMENT = 'EVENT_MANAGEMENT';
  public static MARGINS_MANAGEMENT = 'MARGINS_MANAGEMENT';
  public static SPORT_TYPE_MANAGEMENT = 'SPORT_TYPE_MANAGEMENT';
  public static DASHBOARD_MAIN_SCREEN = 'DASHBOARD_MAIN_SCREEN';
  public static ROLES_AND_PERMISSIONS_SCREEN = 'ROLES_AND_PERMISSIONS_SCREEN';
  public static USERS_PERMISSIONS_SCREEN = 'USERS_PERMISSIONS_SCREEN';
  public static SPORTSBOOK_CASHOUT_SCREEN = 'SPORTSBOOK_CASHOUT_SCREEN';
  public static SPORTSBOOK_CASHOUT_PROFITABILITY_REPORTS_SCREEN = 'SPORTSBOOK_CASHOUT_PROFITABILITY_REPORTS_SCREEN';
  public static SPORTSBOOK_CASHOUT_PROFITABILITY_SUMMARY_SREPORTS_SCREEN =
    'SPORTSBOOK_CASHOUT_PROFITABILITY_SUMMARY_SREPORTS_SCREEN';
  public static SPORTSBOOK_EVENTS_LIST_SCREEN = 'SPORTSBOOK_EVENTS_LIST_SCREEN';
  public static SPORTSBOOK_EVENTS_MANAGEMENT_SCREEN = 'SPORTSBOOK_EVENTS_MANAGEMENT_SCREEN';
  public static SPORTSBOOK_EVENTS_MANAGEMENT_SCREEN_SEARCH_ACTION = 'SPORTSBOOK_EVENTS_MANAGEMENT_SCREEN_SEARCH_ACTION';
  public static BETSLIPS_LIST_SCREEN = 'BETSLIPS_LIST_SCREEN';
  public static BETSLIPS_DATA_SCREEN = 'BETSLIPS_DATA_SCREEN';
  public static BETSLIPS_DATA_SCREEN_SEARCH_ACTION = 'BETSLIPS_DATA_SCREEN_SEARCH_ACTION';
  public static DEPOSITS_WITHDRAWALS_SCREEN = 'DEPOSITS_WITHDRAWALS_SCREEN';
  public static CUSTOMERS_DEPOSIT_WITHDRAW_SCREEN = 'CUSTOMERS_DEPOSIT_WITHDRAW_SCREEN';
  public static CUSTOMER_DEPOSIT_LIMIT = 'CUSTOMER_DEPOSIT_LIMIT';
  public static CUSTOMER_DEPOSITS_WITHDRAWALS_TAB_SCREEN = 'CUSTOMER_DEPOSITS_WITHDRAWALS_TAB_SCREEN';
  public static CUSTOMER_SELF_EXCLUSION = 'CUSTOMER_SELF_EXCLUSION';
  public static SETTINGS_BRAND_SETTINGS_SCREEN = 'SETTINGS_BRAND_SETTINGS_SCREEN';
  public static BONUS_CONNECT_BONUS_TO_USER_SCREEN = 'BONUS_CONNECT_BONUS_TO_USER_SCREEN';
  public static BONUS_SPORTBETTING_FREE_BET_SCREEN = 'BONUS_SPORTBETTING_FREE_BET_SCREEN';
  public static ONLINE_BONUS_FREE_BET_CAMPAIGN_REPORT_SCREEN = 'ONLINE_BONUS_FREE_BET_CAMPAIGN_REPORT_SCREEN';
  public static ONLINE_BONUS_FREE_BET_PER_USER_CAMPAIGN_REPORT_SCREEN =
    'ONLINE_BONUS_FREE_BET_PER_USER_CAMPAIGN_REPORT_SCREEN';
  public static EXPENSES_MANAGEMENT_SCREEN = 'EXPENSES_MANAGEMENT_SCREEN';
  public static EXPENSES_REQUESTS_SCREEN = 'EXPENSES_REQUESTS_SCREEN';
  public static EXPENSES_MONTH_VIEW_SCREEN = 'EXPENSES_MONTH_VIEW_SCREEN';
  public static EXPENSES_REPORTS_SCREEN = 'EXPENSES_REPORTS_SCREEN';
  public static LOTTO_GAME_EDITOR_SCREEN = 'LOTTO_GAME_EDITOR_SCREEN';
  public static LOTTO_TOTAL_REPORT_SCREEN = 'LOTTO_TOTAL_REPORT_SCREEN';
  public static LOTTO_LOGS_SCREEN = 'LOTTO_LOGS_SCREEN';
  public static LOTTO_TYPE_MANAGEMENT = 'LOTTO_TYPE_MANAGEMENT';
  public static LOTTO_TICKETS_SCREEN = 'LOTTO_TICKETS_SCREEN';
  public static POOL_TEMPLATES_SCREEN = 'POOL_TEMPLATES_SCREEN';
  public static POOL_POOLS_SCREEN = 'POOL_POOLS_SCREEN';

  // ONLINE -> Customer PAGES
  public static CUSTOMER_LIST_SCREEN = 'CUSTOMER_LIST_SCREEN';
  public static CUSTOMER_PROFILE_SCREEN = 'CUSTOMER_PROFILE_SCREEN';
  public static CUSTOMER_PROFILE_SCREEN_SEARCH_ACTION = 'CUSTOMER_PROFILE_SCREEN_SEARCH_ACTION';
  public static CUSTOMER_BETSLIPS_SCREEN = 'CUSTOMER_BETSLIPS_SCREEN';
  public static CUSTOMER_STATEMENTS_SCREEN = 'CUSTOMER_STATEMENTS_SCREEN';
  public static CUSTOMER_BALANCE_SCREEN = 'CUSTOMER_BALANCE_SCREEN';
  public static CUSTOMER_BONUS_SCREEN = 'CUSTOMER_BONUS_SCREEN';
  public static CUSTOMER_BONUS_ACTIONS_ACTION = 'CUSTOMER_BONUS_ACTIONS_ACTION';
  public static CUSTOMER_PROFILE_SCREEN_LINKED_ACCOUNTS_SECTION = 'CUSTOMER_PROFILE_SCREEN_LINKED_ACCOUNTS_SECTION';
  public static CUSTOMER_RESPONSIBLE_GAMBLING_SCREEN = 'CUSTOMER_RESPONSIBLE_GAMBLING_SCREEN';
  public static CUSTOMER_CARE_SCREEN = 'CUSTOMER_CARE_SCREEN';
  public static CUSTOMER_USER_ACCESS_SCREEN = 'CUSTOMER_USER_ACCESS_SCREEN';
  public static CUSTOMER_USER_ACCESS_LOGOUT_ACTION = 'CUSTOMER_USER_ACCESS_LOGOUT_ACTION';
  public static VOUCHER_MENU_ITEM = 'VOUCHER_MENU_ITEM';
  public static VOUCHER_BATCH_SUB_ITEM = 'VOUCHER_BATCH_SUB_ITEM';
  public static VOUCHER_VOUCHER_LIST = 'VOUCHER_VOUCHER_LIST';
  public static VOUCHER_REPORT_SUB_ITEM = 'VOUCHER_REPORT_SUB_ITEM';
  public static LOGGED_IN_USER_DETAILS = 'LOGGED_IN_USER_DETAILS';
  public static DEPOSIT_WITHOUT_COMMISSION = 'DEPOSIT_WITHOUT_COMMISSION';

  // ONLINE -> CMS PAGES
  public static CMS_LANGUAGE_MANAGEMENT_SCREEN = 'CMS_LANGUAGE_MANAGEMENT_SCREEN';
  public static CMS_BANNER_MANAGEMENT_SCREEN = 'CMS_BANNER_MANAGEMENT_SCREEN';
  public static CMS_PLACEMENT_VIEW_SCREEN = 'CMS_PLACEMENT_VIEW_SCREEN';
  public static CMS_HTML_MANAGEMENT_SCREEN = 'CMS_HTML_MANAGEMENT_SCREEN';
  public static CMS_SEO_MANAGEMENT_SCREEN = 'CMS_SEO_MANAGEMENT_SCREEN';
  public static CMS_MENU_MANAGEMENT_SCREEN = 'CMS_MENU_MANAGEMENT_SCREEN';

  public static CASINO_GAME_MANAGEMENT_SCREEN = 'CASINO_GAME_MANAGEMENT_SCREEN';
  public static CASINO_CATEGORIES_MANAGEMENT_SCREEN = 'CASINO_CATEGORIES_MANAGEMENT_SCREEN';
  public static REPORTS_BRANCH_SUMMARY_REPORT_SCREEN = 'REPORTS_BRANCH_SUMMARY_REPORT_SCREEN';
  public static EXPOSURE_LIVE_AUTHORIZATION_SCREEN = 'EXPOSURE_LIVE_AUTHORIZATION_SCREEN';
  public static EXPOSURE_AUTHORIZATION_HISTORY_SCREEN = 'EXPOSURE_AUTHORIZATION_HISTORY_SCREEN';
  public static EXPOSURE_EVENT_LIMIT_REPORT_SCREEN = 'EXPOSURE_EVENT_LIMIT_REPORT_SCREEN';
  public static EXPOSURE_AUTHORIZATION_REQUEST_SCREEN = 'EXPOSURE_AUTHORIZATION_REQUEST_SCREEN';
  public static PLACEMENT_SCREEN = 'PLACEMENT_SCREEN';

  public static DYNAMIC_REPORTS_ONLINE_PLAYERS_SCREEN = 'DYNAMIC_REPORTS_ONLINE_PLAYERS_SCREEN';
  public static DYNAMIC_REPORTS_ONLINE_REGISTRATION_SCREEN = 'DYNAMIC_REPORTS_ONLINE_REGISTRATION_SCREEN';
  public static DYNAMIC_REPORTS_IDOPAY_TRANSACTIONS_SCREEN = 'DYNAMIC_REPORTS_IDOPAY_TRANSACTIONS_SCREEN';
  public static DYNAMIC_REPORTS_ACCESS_LIST_BY_IP_SCREEN = 'DYNAMIC_REPORTS_ACCESS_LIST_BY_IP_SCREEN';
  public static DYNAMIC_REPORTS_ACCESS_LIST_BY_COUNTRY_SCREEN = 'DYNAMIC_REPORTS_ACCESS_LIST_BY_COUNTRY_SCREEN';
  public static DYNAMIC_REPORTS_PREPAID_CUSTOMERS_SCREEN = 'DYNAMIC_REPORTS_PREPAID_CUSTOMERS_SCREEN';
  public static DYNAMIC_REPORTS_USER_BONUS_STATUS_SCREEN = 'DYNAMIC_REPORTS_USER_BONUS_STATUS_SCREEN';
  public static DYNAMIC_REPORTS_OPERATIONAL_ORDER_PER_MINUTE_SCREEN =
    'DYNAMIC_REPORTS_OPERATIONAL_ORDER_PER_MINUTE_SCREEN';
  public static DYNAMIC_REPORTS_OPERATIONAL_GAP_PER_SECONDS_SCREEN =
    'DYNAMIC_REPORTS_OPERATIONAL_GAP_PER_SECONDS_SCREEN';
  public static DYNAMIC_REPORTS_OPERATIONAL_PENDING_SETTLE_ORDERS_SCREEN =
    'DYNAMIC_REPORTS_OPERATIONAL_PENDING_SETTLE_ORDERS_SCREEN';
  public static DYNAMIC_REPORTS_OPERATIONAL_PREPAID_TICKETS_SCREEN =
    'DYNAMIC_REPORTS_OPERATIONAL_PREPAID_TICKETS_SCREEN';
  public static DYNAMIC_REPORTS_BONUSES_REFUND_BONUS_SUMMARY_SCREEN =
    'DYNAMIC_REPORTS_BONUSES_REFUND_BONUS_SUMMARY_SCREEN';
  public static DYNAMIC_REPORTS_BONUSES_NEXT_BET_MONTHLY_REPORT_SCREEN =
    'DYNAMIC_REPORTS_BONUSES_NEXT_BET_MONTHLY_REPORT_SCREEN';
  public static DYNAMIC_REPORTS_RISK_MARKETS_SINGLES_BY_AMOUNT_SCREEN =
    'DYNAMIC_REPORTS_RISK_MARKETS_SINGLES_BY_AMOUNT_SCREEN';
  public static DYNAMIC_REPORTS_RISK_MARKETS_BY_CLICK_SCREEN = 'DYNAMIC_REPORTS_RISK_MARKETS_BY_CLICK_SCREEN';
  public static DYNAMIC_REPORTS_RISK_BY_MARKETS_COMBO_SCREEN = 'DYNAMIC_REPORTS_RISK_BY_MARKETS_COMBO_SCREEN';
  public static DYNAMIC_REPORTS_RISK_BY_MARKETS_SCREEN = 'DYNAMIC_REPORTS_RISK_BY_MARKETS_SCREEN';
  public static DYNAMIC_REPORTS_USERS_BALANCE_PER_WALLET_SCREEN = 'DYNAMIC_REPORTS_USERS_BALANCE_PER_WALLET_SCREEN';
  public static DYNAMIC_REPORTS_USERS_USER_PAYOUT_SCREEN = 'DYNAMIC_REPORTS_USERS_USER_PAYOUT_SCREEN';
  public static DYNAMIC_REPORTS_AFFILIATE_CELLEXPERT_ACTIVITIES_SCREEN = 'DYNAMIC_REPORTS_AFFILIATE_CELLEXPERT_ACTIVITIES_SCREEN';
  public static DYNAMIC_REPORTS_AFFILIATE_CELLEXPERT_PLAYERS_SCREEN = 'DYNAMIC_REPORTS_AFFILIATE_CELLEXPERT_PLAYERS_SCREEN';
  public static DYNAMIC_REPORTS_AFFILIATE_CELLEXPERT_TRANSACTIONS_SCREEN = 'DYNAMIC_REPORTS_AFFILIATE_CELLEXPERT_TRANSACTIONS_SCREEN';
  public static DYNAMIC_REPORTS_SYSTEM_CONFIGURATION_SCREEN = 'DYNAMIC_REPORTS_SYSTEM_CONFIGURATION_SCREEN';
  public static DYNAMIC_REPORTS_CURRENCY_CONFIGURATION_SCREEN = 'DYNAMIC_REPORTS_CURRENCY_CONFIGURATION_SCREEN';
  public static DYNAMIC_REPORTS_PRODUCTS_CONFIGURATION_SCREEN = 'DYNAMIC_REPORTS_PRODUCTS_CONFIGURATION_SCREEN';
  public static DYNAMIC_REPORTS_FREE_BET_CAMPAIGN_SCREEN = 'DYNAMIC_REPORTS_FREE_BET_CAMPAIGN_SCREEN';
  public static DYNAMIC_REPORTS_FREE_BET_PER_USER_CAMPAIGN_SCREEN = 'DYNAMIC_REPORTS_FREE_BET_PER_USER_CAMPAIGN_SCREEN';
  public static DYNAMIC_REPORTS_MARGIN_CONFIGURATION_SCREEN = 'DYNAMIC_REPORTS_MARGIN_CONFIGURATION_SCREEN';
  public static TICKET_BONUS_SELECTION_BONUS_SCREEN = 'TICKET_BONUS_SELECTION_BONUS_SCREEN';
  public static TICKET_BONUS_REFUND_BONUS_SCREEN = 'TICKET_BONUS_REFUND_BONUS_SCREEN';
  public static TICKET_BONUS_NEXT_BET_BONUS_SCREEN = 'TICKET_BONUS_NEXT_BET_BONUS_SCREEN';

  public static EXPENSES_BRANCHES_REPORTS_SCREEN = 'EXPENSES_BRANCHES_REPORTS_SCREEN';
  public static NOTIFICATIONS_NOTIFICATIONS_SCREEN = 'NOTIFICATIONS_NOTIFICATIONS_SCREEN';
  public static TEMPLATE_MANAGEMENT_SCREEN = 'TEMPLATE_MANAGEMENT_SCREEN';
  public static TEMPLATE_MANAGEMENT_EDIT_BUTTON = 'TEMPLATE_MANAGEMENT_EDIT_BUTTON';
}

export enum ePermissionsLevel {
  none = 0,
  read = 1,
  update = 3,
  create = 7,
  delete = 15,
}

export enum eUserTemplateForUserCell {
  usrNameOnly,
  usrNameAndId,
  NameOnly,
  NameAndId,
}

export enum eRolesLevel {
  Unknown = 0,
  Cashier = 1,
  BranchManager = 2,
  Agent = 3,
  Operator = 4,
  SubAgent = 5,
  SuperAdmin = 10,
  Teller = 12,
  Service = 13,
  OnlineUser = 100,
  OperatorCSR = 103,
  ManagerCSR = 104,
  CustomerSupport = 1067,
}

export enum eSpecialRoles {
  Tier1Support = 'TIER_1_SUPPORT',
  Tier1Manager = 'TIER_1_MANAGER',
}

export class BaseColors {
  public static COLORS = [
    '#FF6384',
    '#36A2EB',
    '#FFCE56',
    '#EE82EE',
    '#2E8B57',
    '#FF0000',
    '#DB7093',
    '#FFA500',
    '#191970',
    '#FAF0E6',
  ];
}
