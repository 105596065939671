import { createReducer, on } from "@ngrx/store";
import { SbGetTeams, SbSetTeams, SbUpdateTeam, SbDeleteTeam, SbUpdateTeamSuccess, TeamsFailure } from "../actions";
import { sportTeamsInitialState, sportTeamsAdapter } from "../adapters";
import { OperationType } from "src/app/sport-betting/common/models";

export const SportTeamsReducer = createReducer(
  sportTeamsInitialState,
  on(SbGetTeams, state => ({ ...state })),
  on(SbSetTeams, (state, { payload }) => {
    return sportTeamsAdapter.upsertOne({
      key: payload.leagueId,
      data: payload.data,
      totalCount: payload.totalCount,
    }, state);
  }),
  on(SbUpdateTeam, (state, { payload }) => {
    return sportTeamsAdapter.updateOne({
      id: payload.leagueId,
      changes: {
        operationResult: null
      }
    }, state);
  }),
  on(SbDeleteTeam, (state, { payload }) => {
    return sportTeamsAdapter.removeOne(payload.leagueId, state);
  }),
  on(SbUpdateTeamSuccess, (state, { payload }) => {
    const leagueId = payload.leagueId;
    const entityIdToUpdate = payload.entity.id;

    return sportTeamsAdapter.updateOne({
      id: leagueId,
      changes: {
        data: state.entities[leagueId].data.map((item) =>
          item.id === entityIdToUpdate ? { ...item, ...payload.entity } : item
        ),
        operationResult: {
          id: entityIdToUpdate,
          isSuccessful: true,
          operationType: OperationType.Update,
        },
      },
    }, state);
  }),
  on(TeamsFailure, (state, payload) => {
    const entityKey = payload.identifier?.leagueId;

    return sportTeamsAdapter.updateOne({
      id: entityKey,
      changes: {
        operationResult: {
          id: payload.identifier?.entityId,
          isSuccessful: false,
          operationType: payload.operationType
        }
      }
    }, state);
  })
);