import { createAction, props } from "@ngrx/store";
import { Filtering } from "src/app/sport-betting/common/models";
import { FailAction } from "src/app/sport-betting/common/models/fail-action.model";
import { EventModel } from "../../models";

const SB_GET_EVENTS = '[SB] Get events';
const SB_SET_EVENTS = '[SB] Set events';
const SB_EVENTS_FAILURE = '[SB CRUD] Event failure';


export const SbGetEvents = createAction(SB_GET_EVENTS, props<{ payload: Filtering }>());
export const SbSetEvents = createAction(SB_SET_EVENTS, props<{
  payload: {
    items: Array<EventModel>,
    totalCount: number;
  }
}>());
export const EventsFailure = createAction(SB_EVENTS_FAILURE, props<FailAction>());