import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { OperationResult } from "src/app/sport-betting/common/models/operation-result.model";
import { EventModel } from "../../models";

export interface EventsState extends EntityState<EventModel> {
  totalCount: number;
  operationResult?: OperationResult;
}

export const eventAdapter: EntityAdapter<EventModel> = createEntityAdapter<EventModel>();
export const eventInitialState: EventsState = eventAdapter.getInitialState({
  totalCount: 0,
});