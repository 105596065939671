import { createReducer, on } from '@ngrx/store';
import * as Actions from '../actions/league.actions';
import { OperationType } from 'src/app/sport-betting/common/models';
import { betTypeLeagueMarginsAdapter, betTypeLeagueMarginsInitialState } from '../adapters/league.adapter';

export const BetTypeLeagueMarginsReducer = createReducer(
  betTypeLeagueMarginsInitialState,
  on(Actions.SbSetBetTypeLeagueMargins, (state, { payload }) => {
    return betTypeLeagueMarginsAdapter.upsertOne({
      key: payload.countryId,
      data: payload.data,
      totalCount: payload.totalCount
    }, state);
  }),
  on(Actions.SbUpdateBetTypeLeagueMargin, state => ({
    ...state,
    operationResult: null
  })),
  on(Actions.SbUpdateBetTypeLeagueMarginSuccess, (state, { payload }) => {
    return betTypeLeagueMarginsAdapter.updateOne({
      id: payload.countryId,
      changes: {
        data: state.entities[payload.countryId]?.data?.map((item) =>
          item.id === payload.leagueId ? { ...item, ...payload } : item
        ),
      }
    }, {
      ...state,
      operationResult: {
        id: payload.leagueId,
        isSuccessful: true,
        operationType: OperationType.Update
      }
    });
  }),
  on(Actions.BetTypeLeagueMarginsFailure, (state, payload) => {
    return {
      ...state,
      operationResult: {
        id: payload.identifier,
        isSuccessful: false,
        operationType: payload.operationType
      }
    };
  })
);