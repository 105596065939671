import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OperationResult } from 'src/app/sport-betting/common/models/operation-result.model';
import { Event } from '../../models';

export interface EventsState extends EntityState<Event> {
  operationResult?: OperationResult;
}

export const eventDetailAdapter: EntityAdapter<Event> = createEntityAdapter<Event>({
  selectId: (item) => item.eventId
});
export const eventDetailInitialState: EventsState = eventDetailAdapter.getInitialState({
});