import { createReducer, on } from "@ngrx/store";
import { sportCounriesAdapter, sportCounriesInitialState, sportTeamsAdapter } from "../adapters";
import { CountiresFailure, SbDeleteSportTypeCountries, SbGetCountries, SbSetCountries, SbUpdateCountry, SbUpdateCountrySuccess } from "../actions";
import { OperationType } from "src/app/sport-betting/common/models";

export const SportCounriesReducer = createReducer(
  sportCounriesInitialState,
  on(SbGetCountries, state => ({ ...state })),
  on(SbSetCountries, (state, { payload }) => {
    return sportCounriesAdapter.setOne({
      key: payload.sportTypeId,
      data: payload.items,
      totalCount: payload.totalCount
    }, state);
  }),
  on(SbDeleteSportTypeCountries, (state, { payload }) => {
    return sportCounriesAdapter.removeOne(payload.sportTypeId, state);
  }),
  on(SbUpdateCountry, (state, { payload }) => {
    return sportTeamsAdapter.updateOne({
      id: payload.sportTypeId,
      changes: {
        operationResult: null
      }
    }, state);
  }),
  on(SbUpdateCountrySuccess, (state, { payload }) => {
    const sportTypeId = payload.sportTypeId;
    const entityIdToUpdate = payload.entity.id;

    return sportCounriesAdapter.updateOne({
      id: sportTypeId,
      changes: {
        data: state.entities[sportTypeId].data.map((item) =>
          item.id === entityIdToUpdate ? { ...item, ...payload.entity } : item
        ),
        operationResult: {
          id: entityIdToUpdate,
          isSuccessful: true,
          operationType: OperationType.Update,
        },
      },
    }, state);
  }),
  on(CountiresFailure, (state, payload) => {
    const entityKey = payload.identifier?.sportTypeId;

    return sportTeamsAdapter.updateOne({
      id: entityKey,
      changes: {
        operationResult: {
          id: payload.identifier?.entityId,
          isSuccessful: false,
          operationType: payload.operationType
        }
      }
    }, state);
  })
);
