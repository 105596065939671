import { createAction, props } from '@ngrx/store';
import { FailAction } from 'src/app/sport-betting/common/models';
import { SoccerMatch } from '../../models';

const SB_GET_SOCCER_MATCH = '[SB] Get Soccer Match';
const SB_SET_SOCCER_MATCH = '[SB] Set Soccer Match';
const SB_SOCCER_MATCH_FAILURE = '[SB CRUD] Soccer Match failure';

export const SbGetSoccerMatch = createAction(SB_GET_SOCCER_MATCH, props<{ id: number }>());
export const SbSetSoccerMatch = createAction(SB_SET_SOCCER_MATCH, props<{
  payload: {
    items: SoccerMatch,
  }
}>());
export const SoccerMatchFailure = createAction(SB_SOCCER_MATCH_FAILURE, props<FailAction>());