import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { OperationResult } from 'src/app/sport-betting/common/models';
import { LeagueMarginViewModel } from '../../models/league-margin.model';

interface BetTypeLeagueMarginsModel {
  key: number;
  data: Array<LeagueMarginViewModel>;
  totalCount: number;
  operationResult?: OperationResult;
}

export type BetTypeLeagueMarginsState = EntityState<BetTypeLeagueMarginsModel>
export const betTypeLeagueMarginsAdapter: EntityAdapter<BetTypeLeagueMarginsModel> = createEntityAdapter<BetTypeLeagueMarginsModel>({
  selectId: (item) => item.key,
});
export const betTypeLeagueMarginsInitialState: BetTypeLeagueMarginsState = betTypeLeagueMarginsAdapter.getInitialState({
  totalCount: 0
});