import { createAction, props } from "@ngrx/store";
import { FailAction, Filtering } from "src/app/sport-betting/common/models";
import { UpdateCountryMargin } from "../../models/update-country-margin.model";
import { CountryMarginViewModel } from "../../models/country.mode";

const SB_GET_BET_TYPE_COUNTRY_MARGINS = '[SBBTM] Get countries margins';
const SB_SET_BET_TYPE_COUNTRY_MARGINS = '[SBBTM] Set countrie margins';
const SB_UPDATE_BET_TYPE_COUNTRY_MARGIN = '[SBBTM] Update countrie margins';
const SB_UPDATE_BET_TYPE_COUNTRY_MARGIN_SUCCESS = '[SBBTM] Update countrie margins SUCCESS';

const FAILURE = '[SBBTM] Failure';

export const SbGetBetTypeCountryMargins = createAction(SB_GET_BET_TYPE_COUNTRY_MARGINS, props<{ payload: Filtering }>());
export const SbSetBetTypeCountryMargins = createAction(SB_SET_BET_TYPE_COUNTRY_MARGINS, props<{
  payload: {
    data: Array<CountryMarginViewModel>,
    totalCount: number;
  }
}>());
export const SbUpdateBetTypeCountryMargin = createAction(SB_UPDATE_BET_TYPE_COUNTRY_MARGIN, props<{ payload: UpdateCountryMargin }>());
export const SbUpdateBetTypeCountryMarginSuccess = createAction(SB_UPDATE_BET_TYPE_COUNTRY_MARGIN_SUCCESS, props<{ payload: UpdateCountryMargin }>());

export const BetTypeCountryMarginsFailure = createAction(FAILURE, props<FailAction>());