import { createReducer, on } from "@ngrx/store";
import { EventsFailure, SbGetEvent, SbSetEvent, SbUpdateEvent, SbUpdateEventSuccess, } from "../actions";
import { eventDetailAdapter, eventDetailInitialState } from "../adapters";
import { OperationType } from "src/app/sport-betting/common/models/enums";

export const EventDetailReducer = createReducer(
  eventDetailInitialState,
  on(SbGetEvent, state => {
    return { ...state };
  }),
  on(SbSetEvent, (state, { payload }) => {
    return eventDetailAdapter.setOne(payload.items, {
      ...state
    });
  }),
  on(EventsFailure, (state, payload) => {
    return {
      ...state,
      operationResult: {
        id: payload.identifier,
        isSuccessful: false,
        operationType: payload.operationType
      }
    };
  }),
  on(SbUpdateEvent, (state) => {
    return {
      ...state,
      operationResult: null
    };
  }),
  on(SbUpdateEventSuccess, (state, { payload }) => {
    return eventDetailAdapter.updateOne({
      id: payload.eventId,
      changes: payload
    }, {
      ...state,
      operationResult: {
        id: payload.eventId,
        isSuccessful: true,
        operationType: OperationType.Update
      }
    });
  })
);
