<div class="grid p-dir-rev p-nogutter global-Searches">
  <div class="grid form-group">
    <ng-template *ngIf="checkPermission(permissionScreen.SPORTSBOOK_EVENTS_MANAGEMENT_SCREEN_SEARCH_ACTION + 'NO_SHOW', permissionLevel.read); then searchEvent;"></ng-template>
    <ng-template *ngIf="checkPermission(permissionScreen.BETSLIPS_DATA_SCREEN_SEARCH_ACTION, permissionLevel.read); then searchBetslip;"></ng-template>
    <ng-template *ngIf="checkPermission(permissionScreen.CUSTOMER_PROFILE_SCREEN_SEARCH_ACTION, permissionLevel.read); then searchUser;"></ng-template>
  </div>
</div>
<ng-template #searchEvent>
    <div class="col-{{12 / numberOfSearches}} searchInput">
      <span class="p-float-label light-input">
        <input
          [(ngModel)]="searchValueMatchId"
          class="no-spin p-inputtext p-component"
          type="number"
          oninput="validity.valid || (value='')"
          (keyup.enter)="getMatchOrGame($event.target['value'])"
          maxlength="9"
          pInputText
          id="matchOrGamesSearch"/>
        <label for="matchOrGamesSearch">{{'Match_ID_Game_ID' | translate}}</label>
      </span>
  </div>
</ng-template>
<ng-template #searchUser>
    <div class="col-{{12 / numberOfSearches}} searchInput">
      <span class="p-float-label light-input">
        <input
          [(ngModel)]='searchUserId'
          class="no-spin p-inputtext p-component"
          type='text'
          oninput="validity.valid || (value='')"
          (keyup.enter)="getUser($event.target['value'])"
          pInputText
          id="userSearch">
        <label for="userSearch">{{'UserNameId' | translate}}</label>
      </span>
      <!--<span class="p-float-label light-input">
        <p-autoComplete
          id="userAutoCompleteSearch"
          inputId="userAutoCompleteSearch"
          inputStyleClass="p-inputtext p-component light-input"
          class="serachUser"
          field="name"
          [suggestions]="usersResultsForAutoComplete | async"
          emptyMessage="No results"
          [minLength]="3"
          (onSelect)="onSelectUser($event)"
          (completeMethod)="getAutoComplete($event)">
          <ng-template let-user pTemplate="item" class="dropDownElement">
            <ul class="user-info">
              <li class="grid" *ngIf="user.id">
                <label class="col-5">{{'User_ID' | translate}}:</label>
                <span class="col-7 right-side">(#{{user.id}})</span>
              </li>
              <li class="grid" *ngIf="user.userName">
                <label class="col-5">{{'Username' | translate}}:</label>
                <span class="col-7 right-side">{{user.userName}}</span>
              </li>
              <li class="grid" *ngIf="user.name">
                <label class="col-5">{{'Full_name' | translate}}:</label>
                <span class="col-7 right-side">{{user.name}}</span>
              </li>
              <li class="grid" *ngIf="user.phone">
                <label class="col-5">{{'Phone_number' | translate}}:</label>
                <span class="col-7 right-side">{{user.phone}}</span>
              </li>
              <li class="grid" *ngIf="user.email">
                <label class="col-5">{{'Email' | translate}}:</label>
                <span class="col-7 right-side">{{user.email}}</span>
              </li>
              <li class="grid" *ngIf="user.roleId !== null">
                <label class="col-5">{{'Role' | translate}}:</label>
                <span class="col-7 right-side">
                  <app-enum-val-call [id]="user.roleId" [enum]="'eRoles'"></app-enum-val-call>
                </span>
              </li>
            </ul>
          </ng-template>
        </p-autoComplete>
        <label for="userAutoCompleteSearch">{{'User_ID_UserName' | translate}}</label>
      </span>-->
  </div>
</ng-template>
<ng-template #searchBetslip>
    <div class="col-{{12 / numberOfSearches}} searchInput">
      <span class="p-float-label light-input">
        <input
          [(ngModel)]="searchValueOrderId"
          class="no-spin p-inputtext p-component"
          type="number"
          oninput="validity.valid || (value='')"
          (keyup.enter)="getByOrderIdOrBarcode($event.target['value'])"
          maxlength="9"
          pInputText
          id="orderIdOrBarcodeSearch"/>
        <label for="orderIdOrBarcodeSearch">{{'Order_ID_Barcode' | translate}}</label>
      </span>
  </div>
</ng-template>
