import { createAction, props } from "@ngrx/store";
import { Filtering } from "src/app/sport-betting/common/models";
import { FailAction } from "src/app/sport-betting/common/models/fail-action.model";
import { SportLeague } from "../../models/entities";
import { UpdateSportLeague } from "../../models/update-entities";

const SB_GET_LEAGUES = '[SB] Get leagues';
const SB_SET_LEAGUES = '[SB] Set leagues';
const SB_DELETE_LEAGUES = '[SB] Delete league';
const SB_UPDATE_LEAGUE = '[SB CRUD] Update league';
const SB_UPDATE_LEAGUE_SUCCESS = '[SB] Update league SUCCESS';
const SB_LEAGUE_FAILURE = '[SB CRUD] League failure';

export const SbGetLeagues = createAction(SB_GET_LEAGUES, props<{
  payload: Filtering
}>());
export const SbSetLeagues = createAction(SB_SET_LEAGUES, props<{
  payload: {
    countryId: number,
    data: Array<SportLeague>,
    totalCount: number;
  }
}>());
export const SbDeleteCountryLeagues = createAction(SB_DELETE_LEAGUES, props<{
  payload: {
    countryId: number,
  }
}>());
export const SbUpdateLeague = createAction(SB_UPDATE_LEAGUE, props<{
  payload: {
    entity: UpdateSportLeague
    countryId: number
  }
}>());
export const SbUpdateLeagueSuccess = createAction(SB_UPDATE_LEAGUE_SUCCESS, props<{
  payload: {
    entity: UpdateSportLeague
    countryId: number
  }
}>());
export const LeaguesFailure = createAction(SB_LEAGUE_FAILURE, props<FailAction>());
