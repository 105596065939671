import { Component, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ePermissionsLevel, PermissionActionsCodes, PermissionsScreensCodes } from '../../app.config';
import { Store } from '@ngrx/store';
import { getUsersAutocompleteResults } from '../../store/reducers/metadata.reducer';
import { AuthorizationService } from 'abs@shared/authorization/authorization.service';

@Component({
  selector: 'app-app-global-searches',
  templateUrl: './app-global-searches.component.html',
  styleUrls: ['./app-global-searches.component.scss'],
})
export class AppGlobalSearchesComponent {
  @Input() numberOfSearches: number;
  searchValueMatchId: any;
  searchValueOrderId: any;
  searchUserId: any;
  permissionScreen = PermissionsScreensCodes;
  permission = PermissionActionsCodes;
  permissionLevel = ePermissionsLevel;
  usersResultsForAutoComplete: any;
  constructor(private router: Router, private store: Store<any>, private authorizationService: AuthorizationService) {
    this.usersResultsForAutoComplete = this.store.select(getUsersAutocompleteResults);
  }

  @HostListener('input', ['$event']) onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.trim(); // replace(/^\s+|\s+$|\s+(?=\s)/g, '');
  }



  getMatchOrGame(value) {
    this.router.navigate(['/events/Matches/' + value]).then((res) => {
      if (res) {
        this.searchValueMatchId = null;
      }
    });
  }

  getByOrderIdOrBarcode(value) {
    this.router.navigate(['/reports/betslips/' + value]).then((res) => {
      if (res) {
        this.searchValueOrderId = null;
      }
    });
  }

  getUser(value) {
    this.router.navigate(['/crm/search-players/' + value + '/profile']).then((res) => {
      if (res) {
        this.searchUserId = null;
      }
    });
  }

  checkPermission(permission, level = 1): boolean {
    // this.inputSearchesCount = document.querySelectorAll('.searchInput').length;
    return this.authorizationService.hasPermission(permission, level);
  }
}
