import { createAction, props } from "@ngrx/store";
import { FailAction } from "src/app/sport-betting/common/models";
import { Event } from "../../models";

const SB_GET_EVENT = '[SB] Get event';
const SB_SET_EVENT = '[SB] Set event';
const SB_EVENTS_FAILURE = '[SB CRUD] Event failure';
const SB_UPDATE_EVENT = '[SB CRUD] Update event';
const SB_UPDATE_EVENT_SUCCESS = '[SB] Update event SUCCESS';

export const SbGetEvent = createAction(SB_GET_EVENT, props<{ id: number }>());
export const SbSetEvent = createAction(SB_SET_EVENT, props<{
  payload: {
    items: Event,
  }
}>());
export const EventsFailure = createAction(SB_EVENTS_FAILURE, props<FailAction>());
export const SbUpdateEvent = createAction(SB_UPDATE_EVENT, props<{ payload: Event }>());
export const SbUpdateEventSuccess = createAction(SB_UPDATE_EVENT_SUCCESS, props<{ payload: Event }>());
