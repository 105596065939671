import { createAction, props } from "@ngrx/store";
import { Filtering } from "src/app/sport-betting/common/models";
import { FailAction } from "src/app/sport-betting/common/models/fail-action.model";
import { SportCountry } from "../../models/entities";
import { UpdateSportCountry } from "../../models/update-entities";

const SB_GET_COUNTRIES = '[SB] Get countries';
const SB_SET_COUNTRIES = '[SB] Set countries';
const SB_DELETE_COUNTRIES = '[SB] Delete sport types countries';
const SB_UPDATE_COUNTRY = '[SB CRUD] Update country';
const SB_UPDATE_COUNTRY_SUCCESS = '[SB] Update country SUCCESS';
const SB_COUNTRIES_FAILURE = '[SB CRUD] Country failure';


export const SbGetCountries = createAction(SB_GET_COUNTRIES, props<{ payload: Filtering }>());
export const SbSetCountries = createAction(SB_SET_COUNTRIES, props<{
  payload: {
    sportTypeId: number,
    items: Array<SportCountry>,
    totalCount: number;
  }
}>());
export const SbDeleteSportTypeCountries = createAction(SB_DELETE_COUNTRIES, props<{
  payload: {
    sportTypeId: number
  }
}>());
export const SbUpdateCountry = createAction(SB_UPDATE_COUNTRY, props<{
  payload: {
    entity: UpdateSportCountry
    sportTypeId: number
  }
}>());
export const SbUpdateCountrySuccess = createAction(SB_UPDATE_COUNTRY_SUCCESS, props<{
  payload: {
    entity: UpdateSportCountry
    sportTypeId: number
  }
}>());
export const CountiresFailure = createAction(SB_COUNTRIES_FAILURE, props<FailAction>());