import { createReducer, on } from "@ngrx/store";
import { SbGetLeagues, SbSetLeagues, SbDeleteCountryLeagues, SbUpdateLeague, SbUpdateLeagueSuccess, LeaguesFailure } from "../actions";
import { sportLeaguesInitialState, sportLeaguesAdapter, sportTeamsAdapter } from "../adapters";
import { OperationType } from "src/app/sport-betting/common/models";

export const SportLeaguesReducer = createReducer(
  sportLeaguesInitialState,
  on(SbGetLeagues, state => ({ ...state })),
  on(SbSetLeagues, (state, { payload }) => {
    return sportLeaguesAdapter.upsertOne({
      key: payload.countryId,
      data: payload.data,
      totalCount: payload.totalCount
    }, state);
  }),
  on(SbDeleteCountryLeagues, (state, { payload }) => {
    return sportLeaguesAdapter.removeOne(payload.countryId, state);
  }),
  on(SbUpdateLeague, (state, { payload }) => {
    return sportTeamsAdapter.updateOne({
      id: payload.countryId,
      changes: {
        operationResult: null
      }
    }, state);
  }),
  on(SbUpdateLeagueSuccess, (state, { payload }) => {
    const countryId = payload.countryId;
    const entityIdToUpdate = payload.entity.id;

    return sportLeaguesAdapter.updateOne({
      id: countryId,
      changes: {
        data: state.entities[countryId].data.map((item) =>
          item.id === entityIdToUpdate ? { ...item, ...payload.entity } : item
        ),
        operationResult: {
          id: entityIdToUpdate,
          isSuccessful: true,
          operationType: OperationType.Update,
        },
      },
    }, state);
  }),
  on(LeaguesFailure, (state, payload) => {
    const entityKey = payload.identifier?.countryId;

    return sportTeamsAdapter.updateOne({
      id: entityKey,
      changes: {
        operationResult: {
          id: payload.identifier?.entityId,
          isSuccessful: false,
          operationType: payload.operationType
        }
      }
    }, state);
  })
);