import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { BetTypeMargin } from '../../models';
import { OperationResult } from 'src/app/sport-betting/common/models';

export interface BetTypeMarginsState extends EntityState<BetTypeMargin> {
  totalCount: number;
  operationResult?: OperationResult;
}
export const betTypeMarginsAdapter: EntityAdapter<BetTypeMargin> = createEntityAdapter<BetTypeMargin>({
  selectId: (item) => item.id,
});
export const betTypeMarginsInitialState: BetTypeMarginsState = betTypeMarginsAdapter.getInitialState({
  totalCount: 0,
  entities: null,
});