import { createAction, props } from "@ngrx/store";
import { FailAction, Filtering } from "src/app/sport-betting/common/models";
import { UpdateLeagueMargin } from "../../models/update-league-margin.model";
import { LeagueMarginViewModel } from "../../models/league-margin.model";

const SB_GET_BET_TYPE_LEAGUE_MARGINS = '[SBBTM] Get league margins';
const SB_SET_BET_TYPE_LEAGUE_MARGINS = '[SBBTM] Set league margins';
const SB_UPDATE_BET_TYPE_LEAGUE_MARGIN = '[SBBTM] Update league margins';
const SB_UPDATE_BET_TYPE_LEAGUE_MARGIN_SUCCESS = '[SBBTM] Update league margins SUCCESS';

const FAILURE = '[SBBTM] Failure';

export const SbGetBetTypeLeagueMargins = createAction(SB_GET_BET_TYPE_LEAGUE_MARGINS, props<{ payload: Filtering }>());
export const SbSetBetTypeLeagueMargins = createAction(SB_SET_BET_TYPE_LEAGUE_MARGINS, props<{
  payload: {
    countryId: number,
    data: Array<LeagueMarginViewModel>,
    totalCount: number;
  }
}>());
export const SbUpdateBetTypeLeagueMargin = createAction(SB_UPDATE_BET_TYPE_LEAGUE_MARGIN, props<{ payload: UpdateLeagueMargin }>());
export const SbUpdateBetTypeLeagueMarginSuccess = createAction(SB_UPDATE_BET_TYPE_LEAGUE_MARGIN_SUCCESS, props<{ payload: UpdateLeagueMargin }>());

export const BetTypeLeagueMarginsFailure = createAction(FAILURE, props<FailAction>());