import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { OperationResult } from 'src/app/sport-betting/common/models';
import { CountryMarginViewModel } from '../../models/country.mode';

export interface BetTypeCountryMarginsState extends EntityState<CountryMarginViewModel> {
  totalCount: number;
  operationResult?: OperationResult;
}
export const betTypeCountryMarginsAdapter: EntityAdapter<CountryMarginViewModel> = createEntityAdapter<CountryMarginViewModel>({
  selectId: (item) => item.id,
});
export const betTypeCountryMarginsInitialState: BetTypeCountryMarginsState = betTypeCountryMarginsAdapter.getInitialState({
  totalCount: 0,
  entities: null,
});