import { Component, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { Store } from '@ngrx/store';
import { getCreditLimit, getUserData } from '../auth/store/reducers/auth';
import { Actions, ofType } from '@ngrx/effects';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import * as AuthActions from '../auth/store/actions/auth.actions';
import { AuthorizationService } from 'abs@shared/authorization/authorization.service';
import { eSpecialRoles, PermissionsScreensCodes } from 'abs@core/app.config';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { getDynamicViews, isNullOrUndefined } from '../shared/shared-functions/functions';
import { DIRECT_TRANSFER_SUCCESS } from 'abs@core/store/actions/online.actions';
import { getTemplatesPage } from 'abs@core/store/reducers/metadata.reducer';
import { AppMainComponent } from './app.main.component';
import { IDynamicViewsConfig } from 'abs@shared/models/dynamic';
import { UserData } from '../auth/models/user';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './app.breadcrumb.component.html',
})
export class AppBreadcrumbComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  subscription: Subscription;
  specialRoles = eSpecialRoles;
  menuItems: MenuItem[];
  home: MenuItem;
  search: string;
  templates: any[] = [];

  // items: MenuItem[];
  public userData: Observable<UserData>;
  creditLimit: any;
  permission = PermissionsScreensCodes;
  dashboardPermission: boolean;
  userInfoStyle = {
    // float: 'right',
    // padding: '0.75em',
    // color: 'white',
    // 'font-size': 'medium'
  };
  screenPermissions = PermissionsScreensCodes;
  calculatedGlobalSearchObject = 0;
  dynamicBreadcrumbMapping: IDynamicViewsConfig = getDynamicViews();

  constructor(
    private authorizationService: AuthorizationService,
    private store: Store<any>,
    private directTransferSuccess$: Actions,
    private route: ActivatedRoute,
    private router: Router,
    public appMain: AppMainComponent
  ) {
    this.userData = this.store.select(getUserData);
    this.creditLimit = this.store.select(getCreditLimit);

    // Initial navigation
    const isDynamic = this.router.url.includes('/setting/template/');
    this.menuItems = isDynamic ? this.createDynamicBreadcrumbs() : this.createBreadcrumbs(this.route.root);

    this.store
      .select(getTemplatesPage)
      .pipe(take(2))
      .subscribe((res) => {
        this.templates = res;
        if (this.templates && this.router.url.includes('/setting/template/')) {
          this.menuItems = this.createDynamicBreadcrumbs();
        }
      });

    this.directTransferSuccess$
      .pipe(
        ofType(DIRECT_TRANSFER_SUCCESS),
        takeUntil(this.unsubscribe$),
        tap(() => {
          this.store.dispatch(new AuthActions.KeepAlive());
        })
      )
      .subscribe();
  }

  ngOnInit() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const isDynamic = this.router.url.includes('/setting/template/');
      this.menuItems = isDynamic ? this.createDynamicBreadcrumbs() : this.createBreadcrumbs(this.route.root);
    });
    this.calculateGlobalSearchObject();
    this.dashboardPermission = this.authorizationService.hasPermission(PermissionsScreensCodes.DASHBOARD_MAIN_SCREEN);
  }

  createBreadcrumbs(route: ActivatedRoute, url = '/', breadcrumbs: MenuItem[] = []): MenuItem[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      breadcrumbs = breadcrumbs.map((b: MenuItem) => {
        if(b.url.endsWith('/')) {
          b.url = b.url.slice(0, -1);
        }
        return b;
      });
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');
      if (routeURL !== '') {
        url += routeURL + '/';
      }

      let label = child.snapshot.data['breadcrumb'];
      const disabled = !child.snapshot.data['canRedirect'];

      if (label === 'id') {
        label = child.snapshot.data.drb.breadcrumb; // GET ID FROM RESOLVER
      }

      if (!isNullOrUndefined(label)) {
        breadcrumbs.push({ label, url, disabled, target: '_self' });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
    return [];
  }

  createDynamicBreadcrumbs(): MenuItem[] {
    const menuItems = [];
    const routes = this.router.url.split('/');
    const hasExtra = routes.length === 5 ? routes[4] : null;
    const templateIds = this.router.url.split('/')[3];
    const templateId = +templateIds.split('-')[0];
    const templateValueId = +templateIds.split('-')[1];

    if (this.dynamicBreadcrumbMapping[templateIds]) {
      const breadcrumbPaths = this.dynamicBreadcrumbMapping[templateIds]['fullBreadcrumb'].split('/');
      for (const bp of breadcrumbPaths) {
        const supportRedirect = (breadcrumbPaths[breadcrumbPaths.length - 1] === bp) && hasExtra;
        menuItems.push({
          label: bp,
          disabled: !supportRedirect,
          url: supportRedirect ? `/${routes.slice(0, 4).join('/')}` : '/',
          target: '_self'
        });
      }
      if (hasExtra) {
        menuItems.push({
          label: hasExtra,
          disabled: true,
        });
      }
    } else {
      menuItems.push({
        label: 'Dynamic Reports',
        disabled: true,
      });

      if (this.templates) {
        for (const template of this.templates) {
          if (template.id === templateId) {
            menuItems.push({
              label: template.title,
              disabled: true,
            });
            for (const templateValue of template.values) {
              if (templateValue.id === templateValueId) {
                menuItems.push({
                  label: templateValue.title as string,
                  disabled: hasExtra ? false : true,
                  url: hasExtra ? `/${routes.slice(0, 4).join('/')}` : '/',
                  target: '_self',
                });
                break;
              }
            }
            break;
          }
        }

        if (hasExtra) {
          menuItems.push({
            label: hasExtra,
            disabled: true,
          });
        }
      }
    }

    return menuItems;
  }

  checkPermission(permission, level = 1): boolean {
    return this.authorizationService.hasPermission(permission, level);
  }

  calculateGlobalSearchObject() {
    [
      this.permission.SPORTSBOOK_EVENTS_MANAGEMENT_SCREEN_SEARCH_ACTION + 'NO_SHOW',
      this.permission.BETSLIPS_DATA_SCREEN_SEARCH_ACTION,
      this.permission.CUSTOMER_PROFILE_SCREEN_SEARCH_ACTION,
    ].forEach((permission) => {
      if (this.checkPermission(permission)) {
        this.calculatedGlobalSearchObject++;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
