<div class="layout-breadcrumb-container flex-wrap">
  <div class="layout-breadcrumb-left-items">

    <a class="menu-button" (click)="appMain.onMenuButtonClick($event)" *ngIf="dashboardPermission && appMain.isStatic() && !appMain.isMobile()" [routerLink]="['/home']"><i class="pi pi-bars"></i></a>
<!--    <i  *ngIf="!dashboardPermission" class="pi pi-bars">home</i>-->
    <p-breadcrumb [model]="menuItems" styleClass="layout-breadcrumb py-2"></p-breadcrumb>
  </div>
  <div class="layout-breadcrumb-right-items pr-5">
<!--        <ng-template *ngIf="checkPermission([permission.SPORTSBOOK_EVENTS_MANAGEMENT_SCREEN, permission.BETSLIPS_DATA_SCREEN, permission.CUSTOMER_PROFILE_SCREEN]); then globalSearches;"></ng-template>-->
<!--        <ng-template *ngIf="calculatedGlobalSearchObject > 0 && (userData | async)?.role.codeAttribute !== specialRoles.CustomerSupportTie1; then globalSearches;"></ng-template>-->
<!--        <ng-template *ngIf="calculatedGlobalSearchObject > 0 && ![specialRoles.Tier1Support.toString(), specialRoles.Tier1Manager.toString()].includes((userData | async)?.role.codeAttribute); then globalSearches;"></ng-template>-->
        <ng-template *ngIf="calculatedGlobalSearchObject > 0; then globalSearches;"></ng-template>
        <ng-template #globalSearches>
            <app-app-global-searches [numberOfSearches] = calculatedGlobalSearchObject></app-app-global-searches>
        </ng-template>


        <ul class="user-balance" [ngStyle]="userInfoStyle" *ngIf="checkPermission(screenPermissions.LOGGED_IN_USER_DETAILS)">
            <!--        <span>Name</span>-->
            <li>
                Balance:
                <span class="balance-data"
                      [ngStyle]="{'background-color': (userData | async).balance > 0 ? '' : 'red'}">{{(userData | async).balance | number : '1.0-2'}}</span>
                <span class="currency">{{(userData | async).currency.name}}</span>
            </li>
            <li class="divider"></li>
            <li>Holdings: {{(userData | async).holding | number : '1.0-2'}}</li>
            <li class="divider"></li>
            <li>Credit Limit: {{(creditLimit | async)}}</li>
        </ul>
    </div>
</div>
