import { createReducer, on } from '@ngrx/store';
import * as Actions from '../actions/bet-types.actions';
import { betTypesAdapter, betTypesInitialState } from '../adapters';
import { OperationType } from 'src/app/sport-betting/common/models';

export const BetTypesReducer = createReducer(
  betTypesInitialState,
  on(Actions.SbSetBetTypes, (state, { payload }) => {
    return betTypesAdapter.setAll(payload.data, {
      ...state,
      totalCount: payload.totalCount
    });
  }),
  on(Actions.SbUpdateBetType, state => ({
    ...state,
    operationResult: null
  })),
  on(Actions.SbUpdateBetTypeSuccess, (state, { payload }) => {
    return betTypesAdapter.updateOne({
      id: payload.id,
      changes: {
        ...payload,
        groups: payload.groupIds
      }
    }, {
      ...state,
      operationResult: {
        id: payload.id,
        isSuccessful: true,
        operationType: OperationType.Update
      }
    });
  }),
  on(Actions.BetTypesFailure, (state, payload) => {
    return {
      ...state,
      operationResult: {
        id: payload.identifier,
        isSuccessful: false,
        operationType: payload.operationType
      }
    };
  })
);